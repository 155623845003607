export const gateway = "https://qat-mcdougall-shopper-api.link-nonprod.wawanesalife.com";
export const assets = "https://qat-assets.link-nonprod.wawanesalife.com/brokers/mcdougall";
export const googleApiKey = "AIzaSyD8GAXLH7FxUmF13tfHgXVAU6fWk66UH3k";
export const brokerConfig = {
	enabled: "yes",
	authorized_nb: "yes",
	authorized_mb: "yes",
	authorized_pe: "yes",
	authorized_yt: "yes",
	authorized_sk: "yes",
	authorized_qc: "no",
	authorized_nt: "yes",
	authorized_nu: "yes",
	authorized_ns: "yes",
	authorized_nl: "yes",
	authorized_on: "yes",
	authorized_ab: "yes",
	authorized_bc: "yes",
	"split": '0.6'
};
export const brokerBranding = {
	"qat-mcdougall-shopper.link-nonprod.wawanesalife.com": {
		a: {
			environment: "qat",
			broker: "mcdougall",
			website: "https://www.mcdougallinsurance.com/",
			themeSwitcher: "show",
			privacyLink: "https://www.mcdougallinsurance.com/privacy-policy/",
			layout: "modern",
			scope: "mcdougall#branding#qat-mcdougall-shopper.link-nonprod.wawanesalife.com#a",
			approve_company: "Link Platform",
			email: "belleville@mcdougallinsurance.com",
			video_link: "null",
			g4: "GTM-KQT2NH5",
			photos: [
				"photo1.jpg",
				"photo2.jpg",
				"photo3.jpg",
				"photo4.jpg",
				"photo5.jpg",
				"photo6.jpg",
				"photo7.jpg",
				"photo8.jpg",
				"photo9.jpg",
			],
			approve_user: "link_mcdougall",
			logoPosition: "menu",
			environment_id: "qat",
			brands: [
				"qat-mcdougall-shopper.link-nonprod.wawanesalife.com",
			],
			maps: "null",
			introduction: "null",
			phone: "(800) 361-0941",
			menuPhone: "button",
			ab: "a",
			logo: "a.png",
			brand_name: "qat-mcdougall-shopper.link-nonprod.wawanesalife.com",
		},
		b: {
			environment: "qat",
			broker: "mcdougall",
			website: "https://www.mcdougallinsurance.com/",
			themeSwitcher: "show",
			privacyLink: "https://www.mcdougallinsurance.com/privacy-policy/",
			layout: "modern",
			scope: "mcdougall#branding#qat-mcdougall-shopper.link-nonprod.wawanesalife.com#b",
			approve_company: "Link Platform",
			email: "belleville@mcdougallinsurance.com",
			video_link: "null",
			g4: "GTM-KQT2NH5",
			photos: [
				"photo1.jpg",
				"photo2.jpg",
				"photo3.jpg",
				"photo4.jpg",
				"photo5.jpg",
				"photo6.jpg",
				"photo7.jpg",
				"photo8.jpg",
				"photo9.jpg",
			],
			approve_user: "link_mcdougall",
			logoPosition: "menu",
			environment_id: "qat",
			brands: [
				"qat-mcdougall-shopper.link-nonprod.wawanesalife.com",
			],
			maps: "null",
			introduction: "null",
			phone: "(800) 361-0941",
			menuPhone: "button",
			ab: "b",
			logo: "b.png",
			brand_name: "qat-mcdougall-shopper.link-nonprod.wawanesalife.com",
		},
	},
};

//updated on Fri Sep 13 2024 02:21:59 GMT+0000 (Coordinated Universal Time)
